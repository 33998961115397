.button {
    cursor: pointer;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

    &:hover {
        // background-color: $dark-gray;
    }

    &:active {
        // background-color: $gray;
    }

    &.active {
        // background-color: $black;

        // * path, text, polygon {
        //     fill: $light-blue;
        // }

        // &:hover {
        //     background-color: $dark-gray;
        // }
    }
}

