.two-columns {
    display: flex;
    width: 100%;
    // height: 100%;
    // align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 14 * $ptr;
    align-items: flex-start;

    @include respond-to($medium-breakpoint) {
        flex-direction: row;
    }

    img {
        width: 100%;
    }

    video {
        width: 100%;
    }
}

    .two-columns__left {
        width: 100%;
        height: 100%;

        @include respond-to($medium-breakpoint) {
            width: 50%;
        }

        overflow-y: scroll;
        scrollbar-width: none;

        img {
            width: 100%;
        }

        video {
            width: 100%;
        }
    }

        // .two-columns__title {
        //     margin-bottom: 40 * $ptr;
        //     font-size: 32 * $ptr;
        // }

        // .two-columns__description {
        //     font-weight: 100;
        // }

    .two-columns__right {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;

        @include respond-to($medium-breakpoint) {
            width: 50%;
        }

        svg {
            width: 100%;
            height: 100%;

            image {
                width: 100%;
                height: 100%;
            }
        }

        img {
            width: 100%;
        }

        video {
            width: 100%;
        }
    }



