.container {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr 10fr;

    grid-template-areas:
        "header"
        "body";

    @include respond-to($medium-breakpoint) {

        grid-template-columns: 1fr 4fr;
        grid-template-rows: 1fr 8fr;

        grid-template-areas:
            "header header"
            "menu body";
    }

    @include respond-to($wide-breakpoint) {
        grid-template-columns: 1fr 6fr;
    }

}

    .container__header {
        height: $header-height-mobile;
        background-color: white;
        z-index: 2;
        position: relative;
        grid-area: header;

        @include respond-to($medium-breakpoint) {
            height: $header-height;
            background-color: transparent;
        }
    }

    .container__menu {
        grid-area: menu;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        display: none;

        @include respond-to($medium-breakpoint) {
          display: flex;
        }

        @include respond-to($wide-breakpoint) {
            zoom: 1.1;
        }
    }

    .container__body {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        grid-area: body;

        @include respond-to($medium-breakpoint) {
            height: 100%;
        }
    }

// refactor
.scrollable-content {
    position: relative;
    height: 100%;
}
