// $sf-font: 'SF Pro Display' !default;
$monserrat-font: 'Montserrat';
// $segoe-font: 'Segoe UI';
$pw-font: 'PWCentraNo2';

@font-face{
  font-family: $pw-font;
  src: url('/assets/fonts/pw/PWCentraNo2-Book.ttf') format('truetype');
  font-weight: 500;
}

@font-face{
  font-family: $pw-font;
  src: url('/assets/fonts/pw/PWCentraNo2-Bold.ttf') format('truetype');
  font-weight: bold;
}

// @font-face{
//   font-family: $monserrat-font;
//   src: url('/assets/fonts/Montserrat/montserrat_regular.ttf') format('truetype');
//   font-weight: 500;
// }

@font-face{
  font-family: $monserrat-font;
  src: url('/assets/fonts/Montserrat/montserrat_bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face{
  font-family: $monserrat-font;
  src: url('/assets/fonts/Montserrat/montserrat_extra_bold.ttf') format('truetype');
  font-weight: 900;
}

// @font-face{
//   font-family: $monserrat-font;
//   src: url('/assets/fonts/Montserrat/montserrat_semi_bold.ttf') format('truetype');
//   font-weight: 600;
// }

// @font-face {
//   font-family: $segoe-font;
//   src: url('/assets/fonts/Segoe/segoe_ui.woff') format('woff');
// }
