.how-section {
    background-color: transparent;

    @include respond-to($medium-breakpoint) {
        background-color: transparent;
    }

    .scrollable-right__left-content {
        justify-content: space-between;
        height: 320 * $ptr;

        @include respond-to($medium-breakpoint) {
            flex: 1 0 0;
            width: 0;
        }
    }

    .scrollable-right__center-content {
        // position: relative;
        @include respond-to($medium-breakpoint) {
            flex: 1 0 82%;
        }
    }

    .scrollable-right__right-content {
        // flex: 1 0 8%;

        // height: 65%;
        // align-self: flex-end;
        // margin-bottom: 20 * $ptr;
        // transform: scaleY(0.7);
    }

    .scrollable-right__description {
        // padding-left: 15 * $ptr;
        // padding-right: 8%;
    }

    .scrollable-right__image {
        // height: 100%;
        justify-content: center;
    }

    .scrollable-right__image-img {
        z-index: 2;
        pointer-events: none;

        @include respond-to($medium-breakpoint) {
            height: 200 * $ptr;
        }
    }

    .scrollable-right__shape {
        display: none;
    }

    // .scrollable-right__button {
    //     display: none;
    // }

    // .how-section__back-button,
    // .how-section__main-button,
    // .how-section__next-button {
    //     display: flex;
    // }

    .progress-bar {
        @include respond-to($medium-breakpoint) {
            top: 250 * $ptr;
            height: calc(100vh - #{$header-height + 300 * $ptr});
        }

        @include respond-to($wide-breakpoint) {
            top: 350 * $ptr;
            height: calc(100vh - #{$header-height + 450 * $ptr});
        }
    }
}

    .how-section__square-position {
        position: absolute;
        top: -10 * $ptr;
        right: 15 * $ptr;
    }

    .how-section__go-back-button {
        position: absolute;
        width: 280 * $ptr;
        height: 230 * $ptr;
        top: 0;
        cursor: pointer;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;

        @include respond-to($medium-breakpoint) {
            left: auto;
            right: auto;
            margin: 0;
        }
    }

    .how-section__subsection {
        background-color: white;
        overflow: hidden;

        &:first-child {
            padding-top: 20 * $ptr;
        }

        // @include respond-to($medium-breakpoint) {
        //     padding-left: 20 * $ptr;
        // }
    }

        .how-section__title {
            margin-bottom: 16 * $ptr;
            color: #1f4a53;
            font-size: 25 * $ptr;
            // line-height: 25 * $ptr;
            // font-family: $monserrat-font;
            font-weight: bold;
        }

        .how-section__subtitle {
            font-size: 18 * $ptr;
            font-weight: bold;
            color: #1f4a53;
            margin-bottom: 16 * $ptr;
            // font-family: $monserrat-font;
        }

        .how-section__text {
            // padding-bottom: 20 * $ptr;
            line-height: 20 * $ptr;
            letter-spacing: 0.05 * $ptr;
            text-align: justify;
            // margin-left: 15 * $ptr;
            color: black;
            font-size: 19 * $ptr;
            line-height: 1.4;
            display: flex;
            flex-direction: column;
            // padding: 0 10 * $ptr;

            @include respond-to($medium-breakpoint) {
                display: block;
                font-size: 16 * $ptr;
            }

            @include respond-to($wide-breakpoint) {
                font-size: 17 * $ptr;
            }

            &.reverse {
                flex-direction: column-reverse;
            }

            &.is-14 {
                font-size: 16 * $ptr;
                line-height: 1.2;
                color: #717171;
            }

            // &.is-16 {
            //     font-size: 17 * $ptr;
            //     line-height: 1.4;
            //     // letter-spacing: 1px;
            // }

            .two-columns__left & {
                @include respond-to($medium-breakpoint) {
                    padding-left: 30 * $ptr;
                }
            }

            .full-width & {
                padding-bottom: 15 * $ptr;
            }

            // .subsection & {
            //     // font-size: 19 * $ptr;
            //     // line-height: 1.5;
            // }

            &.italic {
                font-style: italic;
            }
        }

        .how-section__image {

        }

        .how-section__gif {
            transition: transform 0.2s;
            transform: scale(1.001);
            cursor: pointer;

            &:hover {
                @include respond-to($medium-breakpoint) {
                    transform: scale(1.02);
                }
            }
        }

    .how-section__back-button {
        padding-left: 2px;

        &.hidden {
            display: flex;
            pointer-events: none;
            opacity: 0;
        }
    }

    .how-section__main-button {
        display: flex;
        position: absolute;
        margin: 0;
        margin-top: -4 * $ptr;
        // margin-left: 10 * $ptr;
        font-size: 20 * $ptr;

        @include respond-to($medium-breakpoint) {
            margin-top: -8 * $ptr;
            margin-left: 10 * $ptr;
            // z-index: 3;
        }
    }

    .how-section__next-button {
        display: none;
        // width: 100%;
        justify-content: flex-end;
        padding-right: 2px;

        &--mobile {
            @include respond-to($medium-breakpoint) {
                display: none;
            }
        }

        @include respond-to($medium-breakpoint) {
            display: flex;
        }
    }
