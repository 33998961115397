.header {
    width: 100%;
    // min-height: auto;
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;

    @include respond-to($medium-breakpoint) {
        grid-template-columns: 1fr 4fr 0.7fr;
    }

    @include respond-to($wide-breakpoint) {
        grid-template-columns: 1fr 6fr 1.2fr;
    }
}

    .header__left {
        display: flex;
        align-items: center;
    }

    .header__center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .header__navbar {
        display: flex;
        width: 100%;
        background-color: transparent;
        justify-content: space-between;
        align-items: center;
    }

        .header__navbar-brand {
            z-index: 2;
            padding-top: 10 * $ptr;
            // padding-bottom: 15 * $ptr;
            padding-left: 10 * $ptr;
            padding-right: 10 * $ptr;
            width: 40 * $ptr;
            cursor: pointer;
            background-color: white;

            @include respond-to($medium-breakpoint) {
                padding-left: 40 * $ptr;
                padding-top: 20 * $ptr;
                width: 60 * $ptr;
                // height: 100 * $ptr;
            }
        }

        .header__navbar-title {
            z-index: 1;
            font-size: 27 * $ptr;
            // font-family: $monserrat-font;
            font-weight: bold;

            @include respond-to($medium-breakpoint) {
                font-size: 34 * $ptr;
            }
        }

        .header__navbar-menu {
            display: flex;
            z-index: 2;
            padding-right: 10 * $ptr;
            background-color: transparent;
            box-shadow: none;
            align-items: center;
            pointer-events: auto;
            transition: opacity 0.3s;

            @include respond-to($medium-breakpoint) {
                display: none;
                padding-right: 50 * $ptr;
            }

            &.hidden {
                display: flex;
                opacity: 0;
            }
        }

            .header__navbar-menu-text {
                @include user-select('none');

                color: #010101;
                font-size: 24 * $ptr;
                // font-family: $monserrat-font;
                font-weight: bold;
                text-align: center;
                opacity: 0.94;
                cursor: pointer;

                @include respond-to($medium-breakpoint) {
                    font-size: 26 * $ptr;
                }
            }
