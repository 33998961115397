.contact {}

  .contact__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    @include respond-to($medium-breakpoint) {
      width: 60%;
    }
  }

    .contact__form-input {
      height: 36px;
      border-bottom: 1px solid;
      margin-bottom: 20px;

      input {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0;
        margin: 0;
        border: 0;
        font-family: $pw-font;
        font-size: 14px;
      }
    }

    .contact__form-select {
      &.vscomp-ele {
        max-width: initial;
      }

      .vscomp-toggle-button {
        border: none;
        padding-left: 0;
      }

      .vscomp-wrapper.focused .vscomp-toggle-button,
      .vscomp-wrapper:focus .vscomp-toggle-button {
        box-shadow: none;
      }
    }

    .contact__form-textarea {
      border: 1px solid;
      display: flex;
      margin-bottom: 5px;

      textarea {
        width: 100%;
        height: 80px;
        margin: 0;
        padding: 5px;
        border: 0;
        font-family: $pw-font;
        resize: none;
        font-size: 14px;
      }
    }

    .contact__form-button {
      text-align: center;
      color: black;
      padding: 20px;
      font-size: 18px;
      font-weight: 600;

      border: none;
      background-color: transparent;
      font-family: $pw-font;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      span {
        border-bottom: 1px solid black;
        padding-bottom: 2px;
      }
    }

  .contact__thank-you-message {
    text-align: center;
    font-size: 22 * $ptr;
    font-weight: bold;
  }
