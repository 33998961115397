.side-menu {

}

  .side-menu__items-wrapper {
    display: flex;
    height: 300px;
    flex-direction: column;
    justify-content: space-evenly;
  }


  .side-menu__item {
    color: #306eba;
    font-size: 20px;
    font-weight: bold;

    &.active, &:hover {
      color: #255896;
    }

    &.active {
        text-decoration: underline;
        text-underline-offset: 4px;
    }
  }

