
.full-width {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20 * $ptr;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    img {
        width: 100%;
    }

    video {
        width: 100%;
        padding: 0.5px;
    }
}

.two-thirds-width {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20 * $ptr;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @include respond-to($medium-breakpoint) {
        width: 75%;
    }

    img {
        width: 100%;
    }

    video {
        width: 100%;
        padding: 0.5px;
    }
}

.space {
    margin-bottom: 20 * $ptr;
}

.space-20 {
    margin-bottom: 20 * $ptr;
}

.space-40 {
    margin-bottom: 40 * $ptr;
}
