.home {
    // justify-content: start;
    justify-content: space-between;

    @include respond-to($wide-breakpoint) {
        zoom: 1.1;
    }
}

    .home__header {
        @include respond-to($medium-breakpoint) {
            height: 0 * $ptr;
        }
    }

    .home__body {
        width: 100%;
        max-height: 90%;
        position: absolute;
        overflow-y: scroll;

        @include respond-to($medium-breakpoint) {
            position: initial;
            max-height: none;
            overflow: auto;
        }
    }

        .home__wrapper {
            display: flex;
            position: relative;
            // padding-top: 40 * $ptr;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include respond-to($medium-breakpoint) {
                // flex-direction: row;
                // align-items: flex-start;
            }
        }

            .home__title {
                z-index: 2;
                width: 90%;
                height: 100%;
                display: flex;
                justify-content: center;
                // margin: 0 auto;

                @include respond-to($medium-breakpoint) {
                    width: 100%;
                    // position: absolute;
                    // padding-left: 100 * $ptr;
                    // width: 600 * $ptr;
                    // max-width: 50%;
                    // margin: 0;
                }
            }

                .home__title-1 {
                    position: relative;
                    z-index: 1;
                    margin-top: 20 * $ptr;
                    margin-bottom: 0 * $ptr;
                    // color: #3f91a5;
                    // color: $dark-blue-color;
                    color: #1d1d1d;
                    font-size: 30 * $ptr;
                    font-family: $monserrat-font;
                    font-weight: 600;
                    text-align: left;
                    line-height: 1.1;
                    // background: linear-gradient(90deg, #3f91a5 0%, #7bf0f1 60%);
                    // -webkit-background-clip: text; // needed for chrome
                    // background-clip: text;
                    // -webkit-text-fill-color: transparent;

                    @include respond-to($medium-breakpoint) {
                        margin: 0;
                        position: fixed;
                        top: 35px;
                        width: 75%;
                        text-align: center;
                        font-size: 37 * $ptr;
                        margin-bottom: 40 * $ptr;
                        // line-height: 1.1;
                        // font-size: 70 * $ptr;
                        // line-height: 5.2rem;
                    }

                    @include respond-to($wide-breakpoint) {
                        font-size: 44 * $ptr;
                    }
                }

                .home__title-2 {
                    position: relative;
                    z-index: 1;
                    color: #1f1f1f;
                    font-size: 32 * $ptr;
                    font-family: $monserrat-font;
                    font-weight: 900;
                    text-align: left;

                    @include respond-to($medium-breakpoint) {
                        font-size: 59 * $ptr;
                        line-height: 67 * $ptr;
                        width: 90%;
                    }
                }

                    .home__title-2-stroke {
                        position: absolute;
                        top: 2 * $ptr;
                        left: 2 * $ptr;
                        color: #ffffff;
                        -webkit-text-stroke: 1px #1f1f1f;

                        @include respond-to($medium-breakpoint) {
                            top: 6 * $ptr;
                            left: 4 * $ptr;
                        }
                    }

                    .home__title-2-fill {
                        position: relative;
                    }

                .home__title-dots {
                    margin-top: 12 * $ptr;

                    svg {
                        width: 73%;
                        height: 100%;

                        @include respond-to($medium-breakpoint) {
                            width: 83%;
                        }
                    }
                }

                .home__title-3 {
                    position: relative;
                    z-index: 1;
                    color: #ffffff;
                    font-size: 37 * $ptr;
                    font-family: $monserrat-font;
                    font-weight: 900;
                    text-align: left;
                    -webkit-text-stroke: 1px #1f1f1f; //this is experimental if any problems use the one below.
                    // text-shadow: -3px 0 #1f1f1f, 0 3px #1f1f1f, 3px 0 #1f1f1f, 0 -3px #1f1f1f;

                    @include respond-to($medium-breakpoint) {
                        font-size: 60 * $ptr;
                        -webkit-text-stroke: 3px #1f1f1f;
                        line-height: 5rem;
                    }
                }

                .home__button {
                    display: none;
                    margin-top: 20 * $ptr;
                    width: 219 * $ptr;
                    height: 48 * $ptr;
                    color: #ffffff;
                    font-size: 14 * $ptr;
                    font-family: $monserrat-font;
                    font-weight: 600;
                    background: linear-gradient(90deg, #3f91a5 0%, #7bf0f1 100%);
                    border-radius: 5 * $ptr;
                    justify-content: center;
                    align-items: center;

                    @include respond-to($medium-breakpoint) {
                        display: flex;
                    }
                }

            .home__image-container {
                display: flex;
                transform-origin: top center;
                max-height: 320 * $ptr;
                max-width: 100vw;
                overflow-x: hidden;

                @include respond-to($medium-breakpoint) {
                    max-height: none;
                    width: 100%;
                    justify-content: center;

                    @include respond-to-vertical(450px) {
                        zoom: 0.7;
                    }

                    @include respond-to-vertical(700px) {
                        zoom: 0.9;
                    }

                    @include respond-to-vertical(980px) {
                        zoom: 1.1;
                    }
                }
            }

                .home__image {
                    display: flex;
                    position: relative;
                    width: 100%;
                    // height: 100%;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    // zoom: 0.5;
                    transform-origin: top center;
                    transform: scale(0.55);

                    @include respond-to($medium-breakpoint) {
                        transform: scale(1);
                        width: 60rem;
                        justify-content: center;
                    }
                }

                    .home__image-shape {
                        position: absolute;
                        // margin-top: 30 * $ptr;
                        // right: 0;
                        height: 30rem;

                        @include respond-to($medium-breakpoint) {
                            // right: auto;
                            padding-right: 50 * $ptr;
                        }

                        svg {
                            width: auto;
                            height: 100%;
                        }
                    }

                    .home__image-arrows {
                        display: none;
                        position: absolute;
                        top: -120 * $ptr;
                        color: #3f91a5;
                        font-size: 24 * $ptr;
                        font-weight: bold;
                        align-items: center;
                        flex-direction: column;
                        transition: opacity 0.5s;

                        // @include respond-to($medium-breakpoint) {
                        //     display: flex;
                        // }

                        svg {
                            // margin-top: -10 * $ptr;
                            transform: scaleY(0.9) !important;
                        }
                    }

                    .home__image-notebook {
                        display: flex;
                        z-index: 1;
                        height: 35rem;

                        img {
                            width: auto;
                            height: 100%;
                        }
                    }

                    .home__image-play-button {
                        // display: flex;
                        // position: absolute;
                        // top: 36%;
                        // z-index: 2;
                        // width: 4rem;
                        // height: 4rem;
                        // cursor: pointer;
                        // transition: opacity 0.5s;

                        display: none;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .home__image-video {
                        position: absolute;
                        top: 62 * $ptr;
                        // left: 154 * $ptr;
                        z-index: 1;
                        width: 607 * $ptr;
                        cursor: pointer;

                        @include respond-to($medium-breakpoint) {
                            // top: 62 * $ptr;
                            left: 176 * $ptr;
                            // width: 607 * $ptr;
                        }

                        video {
                            width: 100%;
                            height: auto;
                        }
                    }

            .home__description {
                width: 90%;
                font-size: 18 * $ptr;

                b {
                    font-size: 14 * $ptr;
                }

                @include respond-to($medium-breakpoint) {
                    margin-top: 20 * $ptr;
                    width: 70%;
                }
            }

    .home__footer {
        display: flex;
        width: 100%;
        height: 45 * $ptr;
        justify-content: center;
    }

        .home__footer-logo {
            // margin-right: 30 * $ptr;
            margin-bottom: 10 * $ptr;
            padding: 10 * $ptr;
            display: flex;
            width: 180 * $ptr;
            position: fixed;
            // bottom: 20px;
            bottom: 0;
            // right: 0;

            @include respond-to($medium-breakpoint) {
                // bottom: 0;
                left: 0;
                margin-left: 20 * $ptr;
                margin-bottom: 20 * $ptr;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

