.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

    .modal__container {
        width: 95%;
        height: 95%;
        background-color: white;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.7), 0 20px 25px 0 rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        @include respond-to($medium-breakpoint) {
            width: 85%;
            height: 90%;
        }

        &.is-70 {
            width: 65%;
            height: 80%;
        }
    }

        .modal__header {
            display: flex;
            justify-content: space-between;
        }

        .modal__header-left {
            padding: 14px;
            padding-bottom: 0;

            @include respond-to($medium-breakpoint) {
                padding: 22px;
                padding-bottom: 0;
            }
        }

            .modal__title {
                font-size: 20px;
                font-weight: 600;
            }

        .modal__header-right {}

            .modal__header-close-icon {
                display: flex;
                padding: 14px;

                @include respond-to($medium-breakpoint) {
                    padding: 22px;
                }
            }

        .modal__body {
            padding: 14px;
            padding-top: 0;
            height: calc(100% - 55px);
            overflow: scroll;

            @include respond-to($medium-breakpoint) {
                padding: 22px;
                padding-top: 5px;
                height: calc(100% - 84px);
            }

            .table__row {
                padding-right: 8px;
            }

            .modal__images {
                display: flex;
                height: 100%;
                margin: 0 auto;
                flex-direction: column;

                @include respond-to($small-breakpoint) {
                    flex-direction: row;
                    width: min-content;
                }
            }

                .modal__image {
                    display: flex;
                    // justify-content: center;
                    // width: 100%;
                    height: 100%;
                    align-items: flex-start;
                    // overflow-y: scroll;

                    @include respond-to($medium-breakpoint) {
                        // align-items: center;
                        // overflow: hidden;
                    }

                    &.portrait {
                        @include respond-to($small-breakpoint) {
                            display: none;
                        }
                    }

                    &.landscape {
                        display: none;

                        @include respond-to($small-breakpoint) {
                            display: flex;
                        }
                    }

                    img {
                        width: 100%;
                        height: auto;

                        @include respond-to($small-breakpoint) {
                            width: auto;
                            height: 100%;
                        }
                    }
                }

                .modal__video {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    video {
                        width: 100%;
                        height: 100%;;
                    }
                }
        }

