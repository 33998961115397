
.half-width {
    display: flex;
    margin-bottom: 50 * $ptr;
    justify-content: center;
    width: 70%;
    margin: 0 auto;

    img {
        width: 100%;
    }

    video {
        width: 100%;
    }
}
