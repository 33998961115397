.carousel-select-container {
    display: flex;
    // margin-top: 30px;
    height: 45vh;
    align-items: center;

    // position: absolute;
    // height: 100%;
    // align-items: left;

    @include respond-to($medium-breakpoint) {
        margin-top: 0;
        margin-bottom: 60 * $ptr;
        height: auto;
        align-items: flex-start;
    }
}

    .carousel-select {
        // position: relative;
        // margin: auto;
        display: flex;

        width: 350 * $ptr;
        height: 25rem;
        overflow: hidden;
        pointer-events: none;

        @include respond-to($medium-breakpoint) {
            margin-left: 30 * $ptr;
            width: 42rem;
            height: 30rem;
        }

        // @include respond-to($small-breakpoint) {
        //     width: 28rem;
        // }

        // @include respond-to($wide-breakpoint) {
        //     margin-left: 100 * $ptr;
        //     width: 750 * $ptr;
        //     height: 350 * $ptr;
        // }
    }

        .carousel-select__boxes {
            display: flex;
            position: relative;
            width: 100%;
            // height: 100%;
        }

        .carousel-select__box {
            @include user-select('none');

            display: flex;
            position: absolute;
            width: 100%;
            min-height: 110 * $ptr;
            color: $dark-blue-color;
            font-size: 40 * $ptr;
            // font-family: $monserrat-font;
            font-weight: bold;
            text-align: left;
            pointer-events: auto;
            cursor: pointer;
            will-change: transform;
            flex-direction: column;
            justify-content: center;

            @include respond-to($wide-breakpoint) {
                min-height: 70 * $ptr;
                font-size: 40 * $ptr;
            }
        }

            .carousel-select__box-title {
                line-height: 1;
            }

            .carousel-select__box-posttitle {
                font-size: 16 * $ptr;
            }
