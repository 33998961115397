.section {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section.hidden {
    display: none;
    opacity: 0;
}

    .section__subsection {
        height: 100%;
        overflow: hidden;
        align-items: flex-start;
        justify-content: flex-start;
        display: grid;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            display: none;
        }

        @include respond-to($medium-breakpoint) {
            grid-template-columns: 4fr 1fr;
        }
    }

        .section__subsection-container {
            // overflow-y: scroll;
            // height: 100%;
            position: relative;
            padding: 0 20px;
            padding-right: 35px;

            @include respond-to($medium-breakpoint) {
                padding: 0;
            }
        }

            .section__subsection-content {
            //     position: relative;
            }

            .section__subsection__title {
                text-align: center;
                color: $dark-blue-color;
                font-size: 34 * $ptr;
                font-weight: bold;
            }

        .section__subsection-progress-bar {
            // display: none;
            position: fixed;
            right: 0;

            // @include respond-to($medium-breakpoint) {
            //     display: block;
            // }
        }

    .subsection {
        padding-top: 20 * $ptr;
        // padding-left: 30 * $ptr;
        // padding-right: 20 * $ptr;
        padding-bottom: 30 * $ptr;
        overflow: hidden;

        @include respond-to($medium-breakpoint) {
            padding-left: 30 * $ptr;
            padding-right: 20 * $ptr;
        }
    }

    .section__button {
        font-size: 22 * $ptr;
        margin-left: 15 * $ptr;
        margin-bottom: 25 * $ptr;
        color: gray;

        &.active, &:hover {
            color: black;
        }

        &.active {
            text-decoration: underline;
            text-underline-offset: 4px;
        }
    }

    .section__menu {
        z-index: 2;
        width: 100%;
        height: 120 * $ptr;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-to($medium-breakpoint) {
            padding-left: 35 * $ptr;
            width: auto;
            height: auto;
            align-self: flex-start;
            align-items: flex-start;
        }
    }


    .section__content{
        display: flex;
        flex-direction: column;
        // overflow: scroll;
        width: 100%;
        justify-content: center;
    }

