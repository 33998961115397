.how {
    .two-columns {
        position: absolute;
        margin-bottom: 20 * $ptr;
        height: 100%;
        justify-content: flex-end;
        // flex-direction: column-reverse;

        @include respond-to($medium-breakpoint) {
            flex-direction: column;
        }
    }

    .two-columns__left {
        margin: 0 20 * $ptr;
        width: calc(100% - 2.5rem);

        @include respond-to($medium-breakpoint) {
            margin: 0;
            width: 85%;
        }

        @include respond-to($wide-breakpoint) {
            width: 80%;
        }
    }

    // .two-columns__left {
    //     display: flex;
    //     // padding-left: 10 * $ptr;
    //     padding-top: 20 * $ptr;
    //     // padding-right: 20 * $ptr;
    //     width: 90%;
    //     height: 35%;
    //     flex-direction: column;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    //     margin: 0 auto;
    //     overflow-y: scroll;

    //     @include respond-to($medium-breakpoint) {
    //         margin: 0;
    //         padding-left: 50 * $ptr;
    //         padding-top: 0;
    //         width: 25%;
    //         height: 100%;
    //         justify-content: center;
    //     }
    // }

    // .two-columns__right {
    //     height: 65%;

    //     @include respond-to($medium-breakpoint) {
    //         width: 80%;
    //     }
    // }
}

    .how__title {
        margin-top: 40 * $ptr;
        margin-bottom: 30 * $ptr;
        color: #4a97aa;
        font-size: 20px;
        // font-family: $segoe-font;
        font-weight: bold;
    }

    .how__description {
        margin-top: 30 * $ptr;
        color: #4e4e4e;
        // font-size: 14 * $ptr!important;
        // font-family: $segoe-font;

        &--mobile {
            @include respond-to($medium-breakpoint) {
                display: none!important;
            }
        }

        &--desktop {
            display: none!important;

            @include respond-to($medium-breakpoint) {
                display: block!important;
            }
        }

        @include respond-to($medium-breakpoint) {
            margin-top: 20 * $ptr;
        }
    }

    .how__label {
        transition: opacity 0.8s;

        &.hidden {
            display: block;
            pointer-events: none;
            opacity: 0;
        }

        .label__text {
            color: #808080;
            cursor: pointer;
            // pointer-events: none;
            transition: transform 0.3s;
            font-size: 12 * $ptr;

            @include respond-to($medium-breakpoint) {
                font-size: 12 * $ptr;
            }

            @include respond-to($wide-breakpoint) {
                font-size: 16 * $ptr;
            }

            &.active {
                color: #606060;
                // pointer-events: auto;
            }

            &:hover {
                color: #3f91a5;
            }
        }
    }


