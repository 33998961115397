.progress-bar {
    display: flex;
    position: absolute;
    top: 15 * $ptr;
    right: -5 * $ptr;
    z-index: 1;
    height: calc(100vh - #{$header-height + 90 * $ptr});

    @include respond-to($medium-breakpoint) {
        right: 30 * $ptr;
    }
}

    .progress-bar__line {
        position: absolute;
        right: 21.5 * $ptr;
        width: 4 * $ptr;
        height: 92%;
        background-color: $light-gray-color;

        @include respond-to($medium-breakpoint) {
            right: 19.5 * $ptr;
            // width: 4 * $ptr;
            height: 100%;
        }
    }

    .progress-bar__line-fill {
        position: absolute;
        right: 20.5 * $ptr;
        width: 6 * $ptr;
        height: 1 * $ptr;
        background-color: $aqua-color;
        transform-origin: top;
        // transition: height 0.5s;

        @include respond-to($medium-breakpoint) {
            right: 18.5 * $ptr;
            width: 6 * $ptr;
        }
    }

    .progress-bar__item {
        position: absolute;
        top: 0;
        right: 5 * $ptr;
        margin-right: 10 * $ptr;
        color: $light-gray-color;
        transition: color 0.5s;
        text-decoration: none;
        width: 18 * $ptr;
        min-height: 18 * $ptr;
        // overflow-x: hidden;

        @include respond-to($medium-breakpoint) {
            width: 130 * $ptr;
        }

        @include respond-to($medium-wide-breakpoint) {
            width: max-content;
        }

        &.active {
            color: $aqua-color;
        }
    }

        .progress-bar__item-title {
            font-size: 14 * $ptr;
            text-align: end;
            text-decoration: none;
            font-weight: 600;
            display: none;
            margin-top: -11px;
            margin-right: 10 * $ptr;
            // line-height: 1;
            padding: 10 * $ptr;

            &.small {
                font-size: 12 * $ptr;
                font-weight: 500;
            }

            @include respond-to($medium-breakpoint) {
                display: block;
            }

            @include respond-to($wide-breakpoint) {
                font-size: 16 * $ptr;
            }
        }

        .progress-bar__item-shape {
            position: absolute;
            top: 0;
            // right: -20 * $ptr;
            right: 0 * $ptr;
            width: 18 * $ptr;
            height: 18 * $ptr;
            background-color: $light-gray-color;
            transition: background-color 0.5s;
            overflow: hidden;

            @include respond-to($medium-breakpoint) {
                width: 14 * $ptr;
                height: 14 * $ptr;
            }

            &.small {
                // right: 0 * $ptr;
                width: 12 * $ptr;
                height: 12 * $ptr;
            }

            &.end {
                border-radius: 50%;
            }

            .active & {
                background-color: $aqua-color;
            }
        }
