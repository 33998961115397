.selection-section {
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    @include respond-to($medium-breakpoint) {
        flex-direction: row;
    }
}

    // .selection-section__title {
    //     font-size: 50 * $ptr;
    // }

    .selection-section__image-container {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: flex-end;
        // height: 43vh;

        @include respond-to($medium-breakpoint) {
            margin-right: 8%;
            height: auto;
        }
    }

        .selection-section__image {
            display: flex;
            position: absolute;
            width: 80%;
            height: 100%;
            justify-content: center;
            align-items: flex-start;
            opacity: 1;
            transition: opacity 0.3s;

            @include respond-to($medium-breakpoint) {
                margin-left: 20 * $ptr;
                width: 100%;
                align-items: center;
            }

            &.hidden {
                display: flex;
                opacity: 0;
            }

            svg {
                width: 90%;
            }

            img {
                // width: 90%;
                height: 80%;

                @include respond-to($medium-breakpoint) {
                    height: auto;
                }
            }
        }

        .selection-section__shape {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
            }

            svg {
                width: 100%;
                height: 100%;
            }

            &--desktop {
                display: none;

                @include respond-to($medium-breakpoint) {
                    display: flex;
                }
            }

            &--mobile {
                display: flex;

                @include respond-to($medium-breakpoint) {
                    display: none;
                }
            }
        }
