@mixin respond-to($breakpoint) {
    @media screen and (min-width: $breakpoint), print {
        @content;
    }
}

@mixin respond-to-vertical($breakpoint) {
    @media screen and (min-height: $breakpoint), print {
        @content;
    }
}

@mixin vendor-prefix($name, $value) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
        #{$vendor}#{$name}: #{$value};
    }
}

@mixin user-select($value) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
        #{$vendor}user-select: #{$value};
    }
}
