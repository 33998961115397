$small-breakpoint:   400px;
$medium-breakpoint:  960px;
$medium-wide-breakpoint:  1280px;
$wide-breakpoint:    1600px;

$primary-color: #000000 !default;

$placeholder-color: #949494 !default;
$background-color: #ffffff !default;

$light-gray-color: #cfcfcf !default;
$gray: #373737 !default;
$dark-gray: #282828 !default;
$light-blue: #1292e8 !default;
$aqua-color: #3f91a5 !default;
$dark-aqua: #1f4a53 !default;
$dark-blue-color: #306eba !default;

// Pixel to rem
$ptr: 0.0625rem;

$header-height: 100 * $ptr;
$header-height-mobile: 75 * $ptr;


html {
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;

    @include respond-to($medium-breakpoint) {
        font-size: 14px;
    }

    @include respond-to($wide-breakpoint) {
        font-size: 14px;
    }
}

html,
body {
    // This line makes the possibility to avoid event.preventDefault(); on on_mouse_wheel
    // Keep studying if this is a better solution

    // position: fixed;
    margin: 0;
    width: 100%;
    height: 100%;

    touch-action: pan-x pan-y;
}

body {
    display: flex;
    position: fixed;
    margin: 0;
    color: $primary-color;
    font-family: $pw-font;
    font-weight: 500;
    // -moz-osx-font-smoothing: grayscale;
    // -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}

a {
    // color: $primary-color;
    // text-decoration: none;
}

.underline {
    text-decoration: underline;
    text-underline-offset: 1px;
    font-style: italic;
}

.danger {
    color: #ea3324;
}

.border {
    border: 1px solid;
    margin-right: 2px;
}

.float-right {
    width: 100%;
    margin-top: 20 * $ptr;
    margin-bottom: 20 * $ptr;

    @include respond-to($medium-breakpoint) {
        float: right;
        width: 50%;
        margin-top: 0;
        margin-left: 20 * $ptr;
        margin-bottom: 8 * $ptr;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.list__less-padding {
    padding: 0;
    padding-left: 20 * $ptr;
}

.margin-20 {
    margin-bottom: 20 * $ptr;
}

.margin-40 {
    margin-bottom: 40 * $ptr;
}

.margin-left-80 {
    // margin-left: 40 * $ptr;
}

img.mobile, video.mobile {
    @include respond-to($medium-breakpoint) {
        display: none;
    }
}

img.desktop, video.desktop {
    display: none;

    @include respond-to($medium-breakpoint) {
        display: flex;
    }
}

// scss-lint:disable QualifyingElement
div.hidden, a.hidden {
    display: none;
    pointer-events: none;
}
// scss-lint:enable QualifyingElement

.reusable-elements {
    display: none;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #707070;
}

::placeholder { /* Firefox, Chrome, Opera */
    color: $placeholder-color;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholder-color;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholder-color;
}
