.gallery {
  padding-top: 8px;
  overflow-y: scroll;
  height: 100%;
  justify-content: flex-start;
}

  .gallery__header {
    height: 100%;
  }

    .gallery__header-image {
      display: flex;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

  .gallery__body {
    padding-bottom: 100px;
  }

    .gallery__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include respond-to($wide-breakpoint) {
          justify-content: flex-start;
        }
    }

    .gallery__item {
      width: 280px;
      /* height: 200px; */
      padding: 18px 12px;
      box-shadow: 0px 0px 8px #bbbbbb;
      background-color: white;
      margin: 12px;
      border-radius: 12px;
      /* border: 1px solid gray; */
      transition: transform 0.2s;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }

      .gallery__item-image {
        /* box-shadow: 0px 0px 8px grey; */
        display: flex;
        margin-bottom: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .gallery__item-description {}

        .gallery__item-text {
            font-size: 11px;
            font-weight: 100;
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 2px;
            letter-spacing: 0.05px;
        }

          .gallery__item-text-left {
            font-weight: bold;
          }

          .gallery__item-text-right {

          }

  .gallery__footer {}

