.menu {
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    // overflow: hidden;
    opacity: 0;
}

    .menu__right-half {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transform: translate3d(100%, 0, 0);
        pointer-events: auto;
        justify-content: flex-end;

        @include respond-to($medium-breakpoint) {
            width: 50%;
        }
    }

        .menu__background {
            position: absolute;
            height: 100%;
            width: 100%;
            // width: auto;
            // height: 120%;

            // @include respond-to($medium-breakpoint) {
            //     width: 100%;
            //     height: auto;
            // }

            img {
                position: absolute;
                right: 0;
                width: auto;
                height: 100%;

                // @include respond-to($medium-breakpoint) {
                //     width: 100%;
                //     height: auto;
                // }
            }
        }


        .menu__close {
            position: absolute;
            top: 50 * $ptr;
            right: 55 * $ptr;
            z-index: 1;
            color: #ffffff;
            font-size: 26 * $ptr;
            // font-family: $monserrat-font;
            font-weight: bold;
            text-align: center;
            opacity: 0.94;
            cursor: pointer;
        }

        .menu__items-wrapper {
            display: flex;
            position: relative;
            margin-top: 30 * $ptr;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;

            @include respond-to($medium-breakpoint) {
                width: 85%;
            }
        }

            .menu__item {
                @include user-select('none');

                margin-bottom: 30 * $ptr;
                color: #ffffff;
                font-size: 33 * $ptr;
                // font-family: $monserrat-font;
                font-weight: bold;
                text-align: center;
                cursor: pointer;
                transition: color 0.3s;

                &:hover {
                    color: #1f4a53;
                }

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
