.label {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1;
    pointer-events: none;
    will-change: transform;
    z-index: 2;
}

    .label__text {
        pointer-events: auto;
        will-change: opacity;
        padding: 5px 25px;

        .hidden & {
            pointer-events: none;
        }
    }
