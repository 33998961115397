.scrollable-right {
    display: flex;
    position: absolute;
    height: 100%;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-around;

    @include respond-to($medium-breakpoint) {
        flex-direction: row;
    }
}

    .scrollable-right__left-content {
        display: flex;
        position: relative;
        width: 100%;
        height: 180 * $ptr;
        align-items: center;
        overflow: hidden;
        flex-direction: row-reverse;
        justify-content: flex-start;
        background-color: transparent;
        z-index: 2;
        // margin-bottom: 5px;

        @include respond-to($medium-breakpoint) {
            width: auto;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1 0 18%;
            // z-index: 0;
            background-color: transparent;
            // margin-bottom: 0;
        }
    }

        .scrollable-right__left-content-top {
            height: 33%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        .scrollable-right__left-content-center {
            width: 250 * $ptr;

            @include respond-to($medium-breakpoint) {
                width: 100%;
                height: 33%;
            }
        }

            .scrollable-right__image {
                display: flex;
                position: relative;
                width: 100%;
                flex-direction: column;
                align-items: center;
                // margin-top: -20 * $ptr;

                @include respond-to($medium-breakpoint) {
                    margin: 0;
                }
            }

                .scrollable-right__image-img {
                    // display: flex;
                    width: 100%;
                    // height: 100%;

                    @include respond-to($medium-breakpoint) {
                        width: 90%;
                        height: auto;
                        border-radius: 5 * $ptr;
                    }
                }

            .scrollable-right__shape {
                position: absolute;
                top: 100%;
                width: 90%;
                height: auto;
            }

        .scrollable-right__left-content-bottom {
            height: 33%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            // .results & {
            //     background-color: white;
            // }
        }

    .scrollable-right__center-content {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        flex-direction: column;
        scrollbar-width: none;
        justify-content: flex-start;
        align-items: flex-start;

        @include respond-to($medium-breakpoint) {
            position: initial;
            width: auto;
            flex: 1 0 64%;
        }
    }

        .scrollable-right__title {
            margin-left: 10 * $ptr;
            color: $dark-blue-color;
            font-size: 34 * $ptr;
            // font-family: $monserrat-font;
            font-weight: bold;
            text-align: center;
            position: fixed;
            z-index: 2;
            margin-top: -50 * $ptr;

            .section__content & {
                margin-left: 0;
                line-height: 1;
                position: initial;
                margin-top: 0;

                @include respond-to($medium-breakpoint) {
                    margin-top: 0;
                    font-size: 34 * $ptr;
                }
            }

            @include respond-to($medium-breakpoint) {
                margin-left: 0;
            }
        }

        .scrollable-right__description {
            display: flex;
            position: absolute;
            flex-direction: column;
            align-items: center;
            overflow-y: scroll;
            scrollbar-width: none;
            height: calc(100% - 34px);
            top: 34px;
            left: 0;
            // padding-left: 19%;
            // padding-right: 21%;
            width: 100%;
            z-index: 1;

            @include respond-to($medium-breakpoint) {
                align-items: flex-start;
                padding-left: 2%;
                padding-right: 15%;
                width: 70%;
                height: calc(100% - 146px);
                top: 50px;
            }

            // .results & {
            //     background-color: white;
            // }
        }

            .scrollable-right__description-cover {
                position: fixed;
                z-index: 1;
                width: 80%;
                height: 20 * $ptr;
                background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                pointer-events: none;
            }

            .scrollable-right__description-text {
                display: flex;
                position: relative;
                margin: 10 * $ptr;
                padding: 0 10 * $ptr;
                color: #717171;
                font-size: 18 * $ptr;
                // font-family: $segoe-font;
                // font-weight: normal;
                line-height: 27 * $ptr;
                text-align: left;
                background-color: white;
                overflow-x: hidden;

                @include respond-to($medium-breakpoint) {
                    margin: 0;
                    padding: 0;
                    overflow-x: initial;
                }
            }

            .scrollable-right__button {
                color: #000000;
                font-size: 28 * $ptr;
                // font-family: $monserrat-font;
                font-weight: bold;
                text-align: center;
                text-decoration: underline;
                padding: 10px;
                z-index: 2;

                @include respond-to($medium-breakpoint) {
                    margin-top: 25 * $ptr;
                    margin-bottom: 25 * $ptr;
                }
            }

        .scrollable-right__center-bottom {
            position: fixed;
            bottom: 0;
            // width: calc(100% - 606px);
            width: 60%;
            padding-left: 1.6%;
            // height: 60px;
            display: none;
            justify-content: space-between;
            z-index: 1;
            background-color: white;

            @include respond-to($medium-wide-breakpoint) {
                display: flex;
                // width: calc(100% - 737px);
            }
        }

    .scrollable-right__right-content {
        display: none;
        position: relative;
        height: 100%;
        align-items: center;
        // overflow: hidden;
        flex-direction: column;
        justify-content: center;

        @include respond-to($medium-breakpoint) {
            display: flex;
            flex: 1 0 18%;
        }
    }
